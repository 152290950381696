// Import dependencies
import React from "react";
import {Col, Row, Tooltip, Card, Button, Divider, Collapse, Input, Form} from "antd";
import {Separator} from "../components/separators/separator";
import Layout from "../layouts/layout";
import SEO from "../components/seo/seo";
import HeaderSeparator from "../components/separators/headerSeparator";
import Header from "../components/header/header";
import {
  PageContainer,
  ParagraphText,
  TitleText,
} from "../styled-components/UILibrary";
import GeneralButton from "../components/form/buttons/GeneralButton";
import { connect, useSelector } from "react-redux";
import CategoryDrawer from "../components/cateogry-drawer/categoty-drawer";
import SearchBar from "../components/search-bar/searchBar";
import "./quoteOther.css";
import { addItemToCart } from "../redux/actions/generalCartAction";
import moment from "moment";
import {generateOrderId} from "../util/orderGenerator";
import {navigate, Link} from "gatsby";
import {getCartQuote} from "../util/cart";
import {MIN_CART_QUOTE} from "../constants/cart";
import Plus from "../assets/icons/plus-black.svg";
import {Constants} from "../constants/Constants";
import StatisticsPopup from "../components/form/forms/statisticsPopup";
import Select from "../components/form/select/select";
import {
  PackaginguUnitSelect,
  EstimatedPackagingUnitsSelect
} from "../data/inputFields";
import Arrow from "../assets/icons/left-arrow-blue.svg";
import {getFirebase} from "../api/firebase";

import ReininCoin from "../assets/images/reinin_coin_quote.png";



const { Panel } = Collapse;
const customPanelStyle = {
    background: Constants.lightGray,
    borderRadius: 13,
    border: 0,
    overflow: "hidden",
    textAlign: "left"
};
class BulkRecycle extends React.Component {
  constructor(props) {
    super(props);

    this.onAddToCart = this.onAddToCart.bind(this);
    this.onCheckout = this.onCheckout.bind(this);
    this.toggleCartDrawer = this.toggleCartDrawer.bind(this);
    this.togglePopup = this.togglePopup.bind(this);
    this.getEarliestPickup = this.getEarliestPickup.bind(this);
    this.updateSelection = this.updateSelection.bind(this);

    const {
      location: { state },
    } = this.props;


        if (typeof window !== 'undefined' && !state) {
            navigate("/", {});
        }

        const {productId, productName, productInfo, fromLog, productSmallName, productSmallInfo, readyToCardProduct} = state ? state : {};
        
        this.state = {
            mobileToolTip: false,
            visible: false,
            visibleQuote: false,
            productId,
            productName,
            productInfo,
            innerWidth: 600,
            category: (productInfo ? productInfo["Department"] : ""),
            open: false,
            popupOpen: false,
            popupContent: "",
            fromLog: fromLog,
            readyToCardProduct: readyToCardProduct,
            weight: (productInfo ? productInfo["AvgWeight"] : 0),
            isMember: false,
            loadMember: true,
            productSmallName: productSmallName,
            productSmallInfo: productSmallInfo,
            packaginguUnitSelection: "",
            estimatedPackagingUnitsSelection: "",
            quantityBulk: {
              "Small bags/boxes (<5kg)": {
                "1-5": 15,
                "6-10": 40,
                "11-20": 75,
                ">20": 115
              },
              "Medium bags/boxes (5-10kg)": {
                "1-5": 21,
                "6-10": 56,
                "11-20": 105,
                ">20": 161
              },
              "Large bags/boxes (>20kg)": {
                "1-5": 60,
                "6-10": 160,
                "11-20": 300,
                ">20": 460
              }
            },
            quoteBulkByCategories: {
              "Members": {
                "Clothes": 0.2,
                "Books": 0.18,
                "Electronics": 0.2,
                "Cookware": 0.1,
              },
              "Non-members": {
                "Clothes": 0.14,
                "Books": 0.13,
                "Electronics": 0.14,
                "Cookware": 0.07,
              }
            },
            recycledItemsBulk: {
              "Clothes": 8,
              "Books": 4,
              "Electronics": 3,
              "Cookware": 2,
            }
        };
    }
    togglePopup = () => {
      this.setState({
        popupOpen: !this.state.popupOpen
      });
    }
    getEarliestPickup = () => {
      let today = new Date();
      let tomorrow = new Date();
      tomorrow.setDate(today.getDate()+1);
      tomorrow.setTime(tomorrow.getTime() + (3600000));
      let monthsArray = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
      return (tomorrow.getHours() > 10 ? tomorrow.getHours() : "0" + tomorrow.getHours()) + ":00 " + monthsArray[tomorrow.getMonth()] + " " + tomorrow.getDate();
    }

    updateSelection = (field, value) => {
        this.setState({[field]: value});
    };

  componentDidMount() {
    this.setState({ innerWidth: document.body.offsetWidth });

    if (this.state.fromLog) {
      this.props.dispatch(
        addItemToCart([
          this.state.readyToCardProduct
        ])
      );
      this.setState({ fromLog: false, open: true });
    }

    if (this.props.loggedIn) {
      const app = import("firebase/app");
      const firestore = import("firebase/firestore");

      // load member data
      Promise.all([app, firestore])
          .then(([firebase]) =>
              getFirebase(firebase)
                  .firestore()
                  .collection("users")
                  .doc(this.props.userToken.uid)
                  .onSnapshot(doc => {
                      // Get data and store in state
                      this.setState({isMember: doc.data().membership_info.is_member, loadMember: false});
                  })
          )
          .catch(() => this.setState({loadMember: false}));
    } else {
      this.setState({loadMember: false});
    }
  }

  onCheckout() {}

  onAddToCart(type) {

    let itemBrand = [this.state.productInfo]
    .filter((product) => {
      return product.Product.toLowerCase().replace("other ", "")
    })
    .map((product) => {
      if ((product.Department == "Books" || product.Department == "Cookware" || product.Department == "Clothes") && !(product.Product.startsWith("OTHER"))) {
        return product.Product.replace("OTHER ", "").replace(" " + product.SubCategory.toUpperCase(), "")
      } else if (product.Department == "Electronics" && product.Product.startsWith("OTHER") && product.Product !=  "OTHER " + product.SubCategory.toUpperCase()) {
        return product.Product.replace("OTHER ", "").replace(" " + product.SubCategory.toUpperCase(), "")
      } else {
        return false
      }
    })
    .filter((value, index, self) => {
      return self.indexOf(value) === index && value != "" ;
    })
    .slice(0, 4)
    .map((product, prodIndex) => {
      return product
    });

    let quoteFinal = +parseFloat(
      this.state.quantityBulk[this.state.packaginguUnitSelection][this.state.estimatedPackagingUnitsSelection]
      *this.state.quoteBulkByCategories[type == "standard" ? "Non-members" : "Members"][this.state.productInfo.Department]
      ).toFixed(2);

    this.props.dispatch(
      addItemToCart([
        {
          productId: this.state.productInfo.ProductID,
          item: this.state.productName,
          quantity: this.state.estimatedPackagingUnitsSelection,
          quote: this.state.quoteBulkByCategories["Non-members"][this.state.productInfo.Department],
          total_price: quoteFinal,
          type: type,
          date: moment().format("YYYY-MM-DD"),
          weight: this.state.quantityBulk[this.state.packaginguUnitSelection][this.state.estimatedPackagingUnitsSelection],
          category: this.state.category,
          brand: itemBrand.length == 0 ? "N/A" : itemBrand[0],
          isBulk: true,
          functionalQuantity: this.state.quantityBulk[this.state.packaginguUnitSelection][this.state.estimatedPackagingUnitsSelection] * this.state.recycledItemsBulk[this.state.productInfo.Department]
        },
      ])
    );
    this.setState(prevState => {
      return {
        count: 1,
        MemberQuote: (this.state.productInfo ? this.state.productInfo["fixed-rate"] : 1),
        Coins: (this.state.productInfo ? parseInt(this.state.productInfo["Coins"]) : 1),
      };
    });
    this.toggleCartDrawer();
  }

  toggleCartDrawer() {
      this.setState(state => ({
        open: !state.open
      }));
   }

  onClickToolTip = () => {
    this.setState(prevState => {
      return {
        visible: !prevState.visible,
      };
    });
  };

  redirectToLogin = () => {

    let itemBrand = [this.state.productInfo]
    .filter((product) => {
      return product.Product.toLowerCase().replace("other ", "")
    })
    .map((product) => {
      if ((product.Department == "Books" || product.Department == "Cookware" || product.Department == "Clothes") && !(product.Product.startsWith("OTHER"))) {
        return product.Product.replace("OTHER ", "").replace(" " + product.SubCategory.toUpperCase(), "")
      } else if (product.Department == "Electronics" && product.Product.startsWith("OTHER") && product.Product !=  "OTHER " + product.SubCategory.toUpperCase()) {
        return product.Product.replace("OTHER ", "").replace(" " + product.SubCategory.toUpperCase(), "")
      } else {
        return false
      }
    })
    .filter((value, index, self) => {
      return self.indexOf(value) === index && value != "" ;
    })
    .slice(0, 4)
    .map((product) => {
      return product
    });

    let quoteFinal = +parseFloat(
      this.state.quantityBulk[this.state.packaginguUnitSelection][this.state.estimatedPackagingUnitsSelection]
      *this.state.quoteBulkByCategories["Non-members"][this.state.productInfo.Department]
      ).toFixed(2);

    navigate("/login", { state: {
      fromRecycle: true,
      logItem: {
                productId:  this.state.productId,
                productName: this.state.productName,
                productInfo: this.state.productInfo,
                fromLog: true,
                itemType: "bulk-recycle",
                readyToCardProduct: {
                  productId: this.state.productInfo.ProductID,
                  item: this.state.productName,
                  quantity: this.state.estimatedPackagingUnitsSelection,
                  quote: this.state.quoteBulkByCategories["Non-members"][this.state.productInfo.Department],
                  total_price: quoteFinal,
                  type: "standard",
                  date: moment().format("YYYY-MM-DD"),
                  weight: this.state.quantityBulk[this.state.packaginguUnitSelection][this.state.estimatedPackagingUnitsSelection],
                  category: this.state.category,
                  brand: itemBrand.length == 0 ? "N/A" : itemBrand[0],
                  isBulk: true,
                  functionalQuantity: this.state.quantityBulk[this.state.packaginguUnitSelection][this.state.estimatedPackagingUnitsSelection] * this.state.recycledItemsBulk[this.state.productInfo.Department]                  
                }
              }
    } });

  }


    render() {
      const {form} = this.props;

            return (
                <div className="layout__container">

                  <Header open={this.state.open} quotePage openDrawer={this.toggleCartDrawer}/>
                  <main className="quote_other_main">
                    <CategoryDrawer />
                    <SEO title="Quote" />
                    <StatisticsPopup popupOpen={this.state.popupOpen} togglePopup={this.togglePopup} content={this.state.popupContent}/>
                    <SearchBar productName={this.state.productName} />


                    <div className="quote_notice_box_mobile">
                      <div className="large_volume_notice" onClick={() =>
                      this.state.productSmallName
                      ? navigate("/recycle", {
                        state: {
                          productId: this.state.productSmallInfo.ProductID,
                          productName: this.state.productSmallName,
                          productInfo: this.state.productSmallInfo
                        },
                      })
                      : navigate("/quoteOther", {
                          state: {
                            productId: this.state.productId,
                            productName: this.state.productName,
                            productInfo: this.state.productInfo
                          },
                      })
                    }>
                        <div className="large_volume_info">
                          <span>Selling a small volume?</span>
                          <span>Use our simple single order form</span>
                        </div>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M12 4L10.59 5.41L16.17 11H4V13H16.17L10.59 18.59L12 20L20 12L12 4Z" fill="#697386"/>
                        </svg>
                      </div>
                    </div>
                        <PageContainer style={{overflowX: "inherit"}}>
                            <div className="quote_container">
                              <div className="quote_title" style={{marginBottom: 12}}>
                                <h6>YOU ARE SELLING</h6>
                              </div>
                              <div className="quote__product">{this.state.productName}</div>

                              <div className="quote_title" style={{marginBottom: 20}}>
                                  <h6>How many are you selling?</h6>
                              </div>
                              <Select
                                  placeholder={ "Select" }
                                  data={PackaginguUnitSelect}
                                  id="packaging_unit"
                                  label="Packaging Unit"
                                  error="Please select a packaging unit."
                                  isRequired
                                  onChange={value => this.updateSelection("packaginguUnitSelection", value)}
                                  form={form}
                              />
                              <Select
                                  placeholder={ "Select" }
                                  data={EstimatedPackagingUnitsSelect}
                                  id="estimated_packaging_units"
                                  label="Estimated Packaging Units"
                                  error="Please select an estimated packaging units."
                                  isRequired
                                  onChange={value => this.updateSelection("estimatedPackagingUnitsSelection", value)}
                                  noForm
                                  form={form}
                              />
                              <div className="quote_title" style={{marginBottom: 3}}>
                                <span onClick={() => {
                                    this.setState({popupContent: "unit_size",popupOpen: true});
                                  }}>Unit sizes explained</span>
                              </div>
                              <div className="quote_statistics_divider"></div>
                              <div className="quote_title" style={{marginBottom: 20}}>
                                <h6>STATISTICS</h6>
                                <span onClick={() => {
                                  this.setState({popupContent: "statistics",popupOpen: true});
                                }}>What’s this?</span>
                              </div>
                                <div className="quote_statics_section">
                                  <h1>Demand score</h1>
                                  <div className="quote_statics_select">
                                  {
                                    ["LOW","AVERAGE","HIGH"].map((score, i) => {
                                        const demandScore = this.state.productInfo ? this.state.productInfo["Demand Score"] : "";
                                        let demandIndex = 2;
                                        if (demandScore == "High") {
                                          demandIndex = 3;
                                        }  else if (demandScore == "Low") {
                                          demandIndex = 1;
                                        }  else if (demandScore == "") {
                                          demandIndex = 0;
                                        }
                                        return (<div
                                        className={i+1 == demandIndex ? "quote_statics_select_item active" : "quote_statics_select_item"}
                                        >{score}</div>);
                                      }
                                    )
                                  }
                                  </div>
                                </div>
                                <div className="quote_statics_section">
                                  <h1>Reusability score</h1>
                                  <div className="quote_statics_select">
                                    {
                                      ["LOW","AVERAGE","HIGH"].map((score, i) => {
                                          const reusabilityScore = this.state.productInfo ? this.state.productInfo["ReusabilityScore"] : "";
                                          let reusabilityIndex = 2;
                                          if (reusabilityScore == "High") {
                                            reusabilityIndex = 3;
                                          }  else if (reusabilityScore == "Low") {
                                            reusabilityIndex = 1;
                                          }  else if (reusabilityScore == "") {
                                            reusabilityIndex = 0;
                                          }
                                          return (<div
                                          className={i+1 == reusabilityIndex ? "quote_statics_select_item active" : "quote_statics_select_item"}
                                          >{score}</div>);
                                        }
                                      )
                                    }
                                  </div>
                                </div>
                                <div className="quote_statics_flex">
                                  <div className="quote_statics_section">
                                    <h1>Recyclable value range</h1>
                                    <p>${this.state.productInfo ? Math.round(this.state.productInfo["NonMemberQuote"] * 100) / 100 : 0} - ${this.state.productInfo ? Math.round(this.state.productInfo["MemberQuote"] * 100) / 100 : 0}</p>
                                  </div>
                                  <div className="quote_statics_section">
                                    <h1>Price certainty</h1>
                                    <p>{this.state.productInfo ? this.state.productInfo["PriceCertainty"] : 'n/a'}</p>
                                  </div>
                                </div>
                                <div className="quote_coins_info" onClick={() => {
                                  this.setState({popupContent: "coin",popupOpen: true});
                                }}>
                                  <span>What are Reinin Coins?</span>
                                  <img src={ReininCoin} />
                                </div>
                            </div>
                                    
                              



          {
            this.state.packaginguUnitSelection && this.state.estimatedPackagingUnitsSelection && 

            <div className={this.state.loadMember ? "quote_bar hide" : "quote_bar"}>
              <div className="quote_btn_container">
                <div className="quote_btn_green_standard" onClick={() => {
                  
                    if (this.props.loggedIn) {
                      this.onAddToCart("standard")
                    } else {
                      this.redirectToLogin();
                    }

                  }}>
                    <span className="quote_value">${
                      +parseFloat(this.state.quantityBulk[this.state.packaginguUnitSelection][this.state.estimatedPackagingUnitsSelection]
                      *
                      this.state.quoteBulkByCategories["Non-members"][this.state.productInfo.Department]).toFixed(2)
                    }</span>
                    <span className="quote_btn_content">Standard Offer</span>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 8.99973H12.17L7.29 13.8797C6.9 14.2697 6.9 14.9097 7.29 15.2997C7.68 15.6897 8.31 15.6897 8.7 15.2997L15.29 8.70973C15.68 8.31973 15.68 7.68973 15.29 7.29973L8.71 0.699727C8.32 0.309727 7.69 0.309727 7.3 0.699727C6.91 1.08973 6.91 1.71973 7.3 2.10973L12.17 6.99973H1C0.45 6.99973 0 7.44973 0 7.99973C0 8.54973 0.45 8.99973 1 8.99973Z" fill="white"/>
                    </svg>
                </div>
                <div className="quote_btn_black_member" onClick={() => {
                  if (this.state.isMember) {
                    this.onAddToCart("member");
                  } else {
                    navigate("/pricing");
                  }
                }}>
                    <span className="quote_value">${
                      +parseFloat(this.state.quantityBulk[this.state.packaginguUnitSelection][this.state.estimatedPackagingUnitsSelection]
                      *
                      this.state.quoteBulkByCategories["Members"][this.state.productInfo.Department]).toFixed(2)
                    }</span>
                    <span className="quote_btn_content">Offer for Members</span>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 8.99973H12.17L7.29 13.8797C6.9 14.2697 6.9 14.9097 7.29 15.2997C7.68 15.6897 8.31 15.6897 8.7 15.2997L15.29 8.70973C15.68 8.31973 15.68 7.68973 15.29 7.29973L8.71 0.699727C8.32 0.309727 7.69 0.309727 7.3 0.699727C6.91 1.08973 6.91 1.71973 7.3 2.10973L12.17 6.99973H1C0.45 6.99973 0 7.44973 0 7.99973C0 8.54973 0.45 8.99973 1 8.99973Z" fill="white"/>
                    </svg>
                </div>
              </div>
          </div>
          }
          <div className="quote_notice_box">
          <div className="large_volume_notice" onClick={() =>
            this.state.productSmallName
            ? navigate("/recycle", {
              state: {
                productId: this.state.productSmallInfo.ProductID,
                productName: this.state.productSmallName,
                productInfo: this.state.productSmallInfo
              },
            })
            : navigate("/quoteOther", {
                state: {
                  productId: this.state.productId,
                  productName: this.state.productName,
                  productInfo: this.state.productInfo
                },
            })
          }>
        <div className="large_volume_info">
          <span>Selling a small volume?</span>
          <span>Use our simple single order form</span>
        </div>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 4L10.59 5.41L16.17 11H4V13H16.17L10.59 18.59L12 20L20 12L12 4Z" fill="#697386"/>
        </svg>
      </div>
      <div className="quote_notice_list">
        <div className="quote_notice_item">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20 3H19V2C19 1.45 18.55 1 18 1C17.45 1 17 1.45 17 2V3H7V2C7 1.45 6.55 1 6 1C5.45 1 5 1.45 5 2V3H4C2.9 3 2 3.9 2 5V21C2 22.1 2.9 23 4 23H20C21.1 23 22 22.1 22 21V5C22 3.9 21.1 3 20 3ZM19 21H5C4.45 21 4 20.55 4 20V8H20V20C20 20.55 19.55 21 19 21Z" fill="#697386"/>
          </svg>
          <span>Earliest pickup <b>{this.getEarliestPickup()}</b></span>
        </div>
        <div className="quote_notice_item">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22 12C22 6.48 17.52 2 12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12ZM15 6.5L18.15 9.65C18.35 9.85 18.35 10.16 18.15 10.36L15 13.5V11H11V9H15V6.5ZM9 17.5L5.85 14.35C5.65 14.15 5.65 13.84 5.85 13.64L9 10.5V13H13V15H9V17.5Z" fill="#697386"/>
          </svg>
          <span>Door-to-door pickup</span>
        </div>
        <div className="quote_notice_item">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.41 10.41L17.58 5.58C17.21 5.21 16.7 5 16.17 5H3C1.9 5 1 5.89 1 7V14C1 15.1 1.9 16 3 16C3 17.66 4.34 19 6 19C7.66 19 9 17.66 9 16H15C15 17.66 16.34 19 18 19C19.66 19 21 17.66 21 16C22.1 16 23 15.1 23 14V11.83C23 11.3 22.79 10.79 22.41 10.41ZM3 10V8C3 7.45 3.45 7 4 7H7V11H4C3.45 11 3 10.55 3 10ZM6 17.25C5.31 17.25 4.75 16.69 4.75 16C4.75 15.31 5.31 14.75 6 14.75C6.69 14.75 7.25 15.31 7.25 16C7.25 16.69 6.69 17.25 6 17.25ZM13 11H9V7H13V11ZM18 17.25C17.31 17.25 16.75 16.69 16.75 16C16.75 15.31 17.31 14.75 18 14.75C18.69 14.75 19.25 15.31 19.25 16C19.25 16.69 18.69 17.25 18 17.25ZM15 11V7H16L20 11H15Z" fill="#697386"/>
          </svg>
          <span>Pickup by Logistics Partner</span>
        </div>
        <div className="quote_notice_item">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM9.29 16.29L5.7 12.7C5.31 12.31 5.31 11.68 5.7 11.29C6.09 10.9 6.72 10.9 7.11 11.29L10 14.17L16.88 7.29C17.27 6.9 17.9 6.9 18.29 7.29C18.68 7.68 18.68 8.31 18.29 8.7L10.7 16.29C10.32 16.68 9.68 16.68 9.29 16.29Z" fill="#697386"/>
          </svg>
          <span>Recycling by Reinin</span>
        </div>
      </div>
      <Link to="/pricing" target="_blank" className="reinin_hero_notice">
        <div className="reinin_hero_notice_logo">
          <svg width="96" height="16" viewBox="0 0 96 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.59289 6.5879C3.43826 5.76875 4.35934 5.23945 5.35612 5L5.5643 8.23251C5.23625 8.28292 4.8388 8.40265 4.37195 8.59168C3.90511 8.76812 3.55813 8.93825 3.33101 9.10208V14.7353H0V5.28355H1.64658L2.59289 6.5879Z" fill="#0AAE67"/>
            <path d="M10.8888 12.5425C11.9613 12.5425 13.0779 12.3787 14.2387 12.051L14.6551 14.3762C13.406 14.7921 12.0307 15 10.5292 15C8.86368 15 7.66502 14.6156 6.9332 13.8469C6.20139 13.0655 5.83548 11.7864 5.83548 10.0095C5.83548 8.24512 6.2077 6.97228 6.95213 6.19093C7.70918 5.40958 8.92676 5.0189 10.6049 5.0189C11.7278 5.0189 12.5921 5.11342 13.1978 5.30246C13.816 5.49149 14.2513 5.80655 14.5037 6.24764C14.7687 6.68872 14.9011 7.31884 14.9011 8.138C14.9011 8.94455 14.7371 9.56837 14.4091 10.0095C14.081 10.4505 13.5258 10.7656 12.7435 10.9546C11.9613 11.1437 10.8509 11.2382 9.41254 11.2382V12.4291C9.76582 12.5047 10.2579 12.5425 10.8888 12.5425ZM10.7752 7.47637C10.2579 7.47637 9.89831 7.53308 9.69643 7.6465C9.50717 7.75992 9.41254 7.97417 9.41254 8.28923V9.49905H10.5292C11.0465 9.49905 11.3998 9.44234 11.589 9.32892C11.7909 9.2029 11.8919 8.97606 11.8919 8.64839V7.47637H10.7752Z" fill="#0AAE67"/>
            <path d="M19.6985 5.24575V14.7353H16.3675V5.68053L19.6985 5.24575Z" fill="#0AAE67"/>
            <path d="M21.3814 5.28355H23.028L23.8797 6.22873C24.9269 5.42218 26.1635 5.0189 27.5892 5.0189C28.4725 5.0189 29.1601 5.20164 29.6522 5.56711C30.1443 5.91998 30.3903 6.41777 30.3903 7.06049V14.7353H27.0593V7.75992H25.6588C25.3055 7.75992 25.0594 7.81033 24.9206 7.91115C24.7818 8.01197 24.7125 8.18211 24.7125 8.42155V14.7353H21.3814V5.28355Z" fill="#0AAE67"/>
            <path d="M35.3082 5.24575V14.7353H31.9772V5.68053L35.3082 5.24575Z" fill="#0AAE67"/>
            <path d="M36.9911 5.28355H38.6377L39.4894 6.22873C40.5366 5.42218 41.7731 5.0189 43.1989 5.0189C44.0821 5.0189 44.7698 5.20164 45.2619 5.56711C45.754 5.91998 46 6.41777 46 7.06049V14.7353H42.669V7.75992H41.2684C40.9152 7.75992 40.6691 7.81033 40.5303 7.91115C40.3915 8.01197 40.3221 8.18211 40.3221 8.42155V14.7353H36.9911V5.28355Z" fill="#0AAE67"/>
            <path d="M58.22 6.64C59.6333 6.64 60.94 6.65333 62.14 6.68C62.7667 4.14667 63.38 2.38667 63.98 1.4C64.38 0.826666 64.78 0.413332 65.18 0.159999C65.34 0.053333 65.5 0 65.66 0C65.8333 0 65.92 0.26 65.92 0.779999C65.92 1.3 65.8733 1.84667 65.78 2.42C65.7 2.99333 65.5933 3.65333 65.46 4.4C65.02 6.85333 64.6867 8.81333 64.46 10.28C64.2467 11.7467 64.14 13.16 64.14 14.52C64.14 14.8933 63.8733 15.08 63.34 15.08C62.5 15.08 61.9267 14.92 61.62 14.6C61.3133 14.2667 61.16 13.6733 61.16 12.82C61.16 11.9533 61.3333 10.5933 61.68 8.74C60.2133 8.67333 59.2867 8.64 58.9 8.64H58.08C57.9333 8.64 57.7867 8.64667 57.64 8.66C57.04 10.9533 56.74 12.7667 56.74 14.1C56.74 14.8867 55.9733 15.28 54.44 15.28C54.1067 15.28 53.9067 15.1133 53.84 14.78C53.7867 14.4333 53.76 13.88 53.76 13.12C53.76 12.3467 54 10.9667 54.48 8.98C53.6667 9.1 53.0733 9.16 52.7 9.16C52.5267 9.16 52.44 9.09333 52.44 8.96C52.44 8.66667 52.6533 8.33333 53.08 7.96C53.52 7.58667 54.16 7.28667 55 7.06C55.4933 5.35333 55.9867 3.95333 56.48 2.86C55.04 2.88667 53.98 3.16667 53.3 3.7C53.0867 3.86 52.9133 4.00667 52.78 4.14C52.6467 4.26 52.5267 4.32 52.42 4.32C52.3267 4.32 52.2333 4.24 52.14 4.08C52.0467 3.92 52 3.70667 52 3.44C52 2.46667 52.82 1.72667 54.46 1.22C55.7667 0.819999 57.2867 0.613333 59.02 0.599999H59.06C59.1533 0.599999 59.28 0.653333 59.44 0.76C59.6133 0.866667 59.7 1.04 59.7 1.28C59.7 1.52 59.6533 1.79333 59.56 2.1C59.48 2.39333 59.3667 2.61333 59.22 2.76C59.14 3.38667 58.8067 4.68 58.22 6.64Z" fill="#212B39"/>
            <path d="M71.2764 12.8C72.3164 12.8 73.3231 12.3867 74.2964 11.56C74.6564 11.2533 74.9497 10.9733 75.1764 10.72C75.4031 10.4533 75.5764 10.32 75.6964 10.32C75.8831 10.32 75.9764 10.5067 75.9764 10.88C75.9764 11.2533 75.8764 11.6733 75.6764 12.14C75.4764 12.5933 75.1431 13.04 74.6764 13.48C73.5297 14.56 71.9564 15.1 69.9564 15.1C68.3697 15.1 67.2897 14.5267 66.7164 13.38C66.5164 12.98 66.4164 12.4067 66.4164 11.66C66.4164 10.9133 66.5631 10.14 66.8564 9.34C67.1497 8.52667 67.5364 7.82667 68.0164 7.24C68.5097 6.65333 69.0764 6.2 69.7164 5.88C70.3564 5.54667 71.0364 5.38 71.7564 5.38C72.4897 5.38 73.0497 5.56 73.4364 5.92C73.8364 6.28 74.0364 6.74667 74.0364 7.32C74.0364 7.89333 73.8897 8.36667 73.5964 8.74C73.3164 9.1 72.9497 9.40667 72.4964 9.66C72.0431 9.9 71.5364 10.12 70.9764 10.32C70.4297 10.5067 69.8897 10.72 69.3564 10.96V11.46C69.3697 11.94 69.5697 12.28 69.9564 12.48C70.3431 12.68 70.7831 12.7867 71.2764 12.8ZM70.9364 7.16C70.2431 7.16 69.7631 7.96667 69.4964 9.58C69.7764 9.39333 70.0364 9.22667 70.2764 9.08C70.5297 8.93333 70.7497 8.79333 70.9364 8.66C71.3364 8.36667 71.5431 8.03333 71.5564 7.66C71.5564 7.32667 71.3497 7.16 70.9364 7.16Z" fill="#212B39"/>
            <path d="M77.8958 11.62L78.0158 14.06C78.0158 14.38 77.8491 14.6667 77.5158 14.92C77.1824 15.1733 76.7291 15.3 76.1558 15.3C75.8224 15.3 75.5891 15.1267 75.4558 14.78C75.3358 14.4333 75.2758 13.9267 75.2758 13.26C75.2758 11.86 75.3424 10.7667 75.4758 9.98C75.6224 9.18 75.9024 8.37333 76.3158 7.56C75.7024 6.97333 75.3958 6.46667 75.3958 6.04C75.3958 5.14667 75.8758 4.7 76.8358 4.7C77.3291 4.7 77.7624 4.96 78.1358 5.48C78.4024 5.84 78.5958 6.26 78.7158 6.74C78.9024 6.80667 79.1358 6.84 79.4158 6.84C79.8024 6.84 80.2491 6.71333 80.7558 6.46L81.2758 6.2C81.4358 6.12 81.5758 6.08 81.6958 6.08C82.0824 6.08 82.4691 6.64667 82.8558 7.78C82.7358 8.3 82.6024 8.8 82.4558 9.28C82.3224 9.76 82.1624 10.34 81.9758 11.02C81.7891 11.7 81.6891 12.16 81.6758 12.4C81.6758 12.6267 81.7491 12.74 81.8958 12.74C82.0958 12.74 82.3424 12.6133 82.6358 12.36C82.9291 12.1067 83.2224 11.8333 83.5158 11.54C83.8091 11.2333 84.0758 10.9533 84.3158 10.7C84.5691 10.4467 84.7424 10.32 84.8358 10.32C85.0224 10.32 85.1158 10.5467 85.1158 11C85.1158 11.92 84.7091 12.8267 83.8958 13.72C83.0958 14.6133 82.1758 15.0533 81.1358 15.04C80.5224 15.04 80.0624 14.7933 79.7558 14.3C79.4491 13.8067 79.2891 13.2067 79.2758 12.5C79.2758 11.1 79.5891 9.77333 80.2158 8.52C79.9224 8.64 79.5891 8.7 79.2158 8.7C78.8558 8.7 78.5958 8.68667 78.4358 8.66C78.3158 9.16667 78.1958 9.68 78.0758 10.2C77.9558 10.7067 77.8958 11.18 77.8958 11.62Z" fill="#212B39"/>
            <path d="M92.1964 13.28C91.1431 14.52 89.8764 15.14 88.3964 15.14C86.9297 15.14 85.8297 14.7667 85.0964 14.02C84.3631 13.26 84.0031 12.32 84.0164 11.2C84.0031 9.61333 84.5031 8.24667 85.5164 7.1C86.5297 5.95333 87.8564 5.38 89.4964 5.38C90.2431 5.38 90.8431 5.47333 91.2964 5.66C92.0831 5.98 92.4764 6.28 92.4764 6.56C92.4764 6.77333 91.9231 6.88667 90.8164 6.9C89.7231 6.9 88.8031 7.28 88.0564 8.04C87.3231 8.78667 86.9564 9.73333 86.9564 10.88C86.9564 11.48 87.1297 11.9867 87.4764 12.4C87.8364 12.8 88.3364 13 88.9764 13C89.6297 13 90.1697 12.8667 90.5964 12.6C89.6497 11.96 89.1764 11.0333 89.1764 9.82C89.1631 9.18 89.3897 8.6 89.8564 8.08C90.3364 7.54667 90.9431 7.28 91.6764 7.28C92.4231 7.26667 92.9564 7.46667 93.2764 7.88C93.5964 8.29333 93.7564 8.82667 93.7564 9.48C93.7564 10.12 93.6031 10.8 93.2964 11.52H93.4164C94.0031 11.5067 94.5097 11.3 94.9364 10.9C95.0964 10.74 95.2297 10.6 95.3364 10.48C95.4564 10.36 95.5764 10.3 95.6964 10.3C95.8831 10.3 95.9764 10.52 95.9764 10.96C95.9764 11.7733 95.7364 12.3867 95.2564 12.8C94.7764 13.2 94.2431 13.4 93.6564 13.4C93.0831 13.4 92.5964 13.36 92.1964 13.28ZM92.0164 10.96C92.2431 10.48 92.3564 10.0133 92.3564 9.56C92.3564 9.10667 92.2097 8.88 91.9164 8.88C91.7964 8.88 91.6964 8.96667 91.6164 9.14C91.5364 9.31333 91.4964 9.48 91.4964 9.64C91.4964 10.1733 91.6697 10.6133 92.0164 10.96Z" fill="#212B39"/>
          </svg>
        </div>
        <span>Join to earn more {"&"} get free pickups</span>
        <div className="reinin_hero_notice_left">
          <svg width="34" height="13" viewBox="0 0 34 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 0L34 13H4C1.79086 13 0 11.2091 0 9V0Z" fill="#9ECBB5"/>
          </svg>
        </div>
        <div className="reinin_hero_notice_right">
          <svg width="84" height="60" viewBox="0 0 84 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M73 0L77 52.5L84 57L84 4C84 1.79086 82.2091 4.02544e-07 80 3.0598e-07L73 0Z" fill="#9ECBB5"/>
            <path d="M84 49L0 60H80C82.2091 60 84 58.2091 84 56V49Z" fill="#0AAE67"/>
          </svg>
        </div>
      </Link>
    </div>
                        </PageContainer>
                    </main>
                </div>
            );
        }
}

// Connect redux to component
const mapStateToProps = state => ({
    cart: state.cart,
    generalCart: state.generalCart,
    configs: state.product.configs,
    loggedIn: state.user.isLoggedIn,
    userToken: state.user.userToken
  });

export default Form.create({name: "bulk-recycle-form"})(connect(mapStateToProps)(BulkRecycle));
